<template>
  <n-modal v-model:show="showLoginModal" transform-origin="center">
    <div class="login-wrap">
      <div class="login-header">
        <img class="close-img" src="@/assets/img/close.png" @click="showLoginModal = false">
      </div>
      <div class="login-content">
        <LoginForm ref="formRef" :isAgree="isAgree" @loginSuccess="loginSuccess" />
        <div class="seq"></div>
        <div class="login-qrcode">
          <div class="login-qrcode-t">
            <svg-icon class="icon" name="wx-two"></svg-icon>
            扫码登录
          </div>
          <div class="login-qrcode-st">使用微信扫一扫、安全登录</div>
          <div class="qrcode">
            <img class="img" v-show="qrcodeUrl" :src="qrcodeUrl" alt="">
            <div class="expire-tip" v-if="isExpired">
              <svg-icon class="icon" name="warning" />
              <span>二维码已失效</span>
              <n-button type="primary" size="small" text @click="initQrcode">点击刷新</n-button>
            </div>
          </div>
        </div>
      </div>
      <div class="login-footer">
        <n-checkbox v-model:checked="isAgree">
          我已经同意并且愿意遵守
          <router-link class="link" to="/service-agreement" target="_blank">《用户服务协议》</router-link>
          和
          <router-link class="link" to="/user-privacy-terms" target="_blank">《隐私协议》</router-link>
        </n-checkbox>
      </div>
    </div>
  </n-modal>
</template>

<script lang="ts" setup>
import { NModal, NCheckbox, NButton } from 'naive-ui'
import { useGlobalStore } from '@/store/useGlobalStore';
import { ref, computed, watch, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserState } from '@/store/useUserStore';
import useQrcodeLogin from './useQrcodeLogin'
import LoginForm from './loginForm.vue'

const router = useRouter();
const route = useRoute();

const globalStore = useGlobalStore()
const showLoginModal = computed({
  get: () => globalStore.showLogin,
  set: (val: boolean) => {
    globalStore.setShowLogin(val)
  }
});

const isAgree = ref(true);

const {
  qrcodeUrl,
  initQrcode,
  isExpired,
  destroyQrcode
} = useQrcodeLogin('' /* 团体id */, loginSuccess);

const formRef = ref<typeof LoginForm>()
const init = () => {
  // 初始化验证码
  formRef.value?.initCaptcha()
  // 初始化二维码
  initQrcode()
}

watch(
  () => showLoginModal.value,
  (newVal) => {
    if (newVal) {
      nextTick(() => { init() })
    } else {
      // 关闭弹窗后，停止二维码的轮询
      destroyQrcode();
    }
  },
  { immediate: true }
)

const userStore = useUserState();
// 登录成功后的回调
function loginSuccess(res: any) {
  userStore.setToken(res.token)
  userStore.setUserInfo(res.user_info)
  if (globalStore.afterLoginPath) {
    router.push({ path: globalStore.afterLoginPath });
  }
  showLoginModal.value = false;
}
if (route.query.showLogin === 'true') {
  showLoginModal.value = true
}
</script>

<style lang="scss" scoped>
.login-wrap {
  width: 745px;
  height: 460px;  // 临时邮箱
  // height: 440px;
  background: #FFFFFF;
  border-radius: 14px;
  position: relative;

  .login-header {
    text-align: right;
    padding: 28px 32px 16px;

    .close-img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .login-content {
    display: flex;
    margin-bottom: 36px;

    .seq {
      margin-top: 32px;
      width: 1px;
      height: 224px;
      background-color: #CCCCCC;
    }

    .login-qrcode {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 348px;

      .login-qrcode-t {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;
        height: 32px;
        display: flex;
        align-items: center;

        .icon {
          font-size: 32px;
          color: #00C800;
          margin-right: 16px;
        }
      }

      .login-qrcode-st {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .qrcode {
        position: relative;
        width: 180px;
        height: 180px;
        border: 1px solid #ccc;
        border-radius: 3px;

        .img {
          width: 100%;
          height: 100%;
        }
        .expire-tip{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255,255,255,0.9);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: 14px;
          .icon{
            font-size: 36px;
            color: var(--error);
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  .login-footer {
    display: flex;
    justify-content: center;
    font-size: 16px;

    .n-checkbox {
      --n-size: 18px !important;
      --n-border-radius: 50% !important;
      --n-font-size: 16px !important;
    }

    .link {
      color: var(--primary);
    }
  }
}
</style>