import axios from 'axios';
import type { AxiosResponse, Method } from 'axios'
import { HttpStatus } from '@/common/constants/codeStatus'
import { useGlobalStore } from '@/store/useGlobalStore'
import { useUserState } from '@/store/useUserStore';
import { DialogReactive, createDiscreteApi } from 'naive-ui';
import { formatParameter, createFormData, alinkDownload } from '@/utils/index';
import { env } from '@/common/constants'
import { fetchEventSource } from '@microsoft/fetch-event-source'
const { message: Message, dialog: Dialog } = createDiscreteApi(['message', 'dialog'])

const ERROR_MSG = '系统错误，请联系客服';
const baseUrl = env.VITE_API_HOST || window.location.origin

// create an axios instance
const service = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  },
  // withCredentials: true // 当跨域请求时，是否携带cookie。项目中使用了proxy解决跨域，不需要这个属性
});

let dialogReactive: DialogReactive;
// request interceptor
service.interceptors.request.use(
  (config) => {
    let params = {
      ...(config.params || config.data || {})
    };

    formatParameter(params, config.method);

    if (config.headers['Content-Type'] === 'multipart/form-data') {
      params = createFormData(params);
    }

    if (config.method === 'get' || config.method === 'delete') {
      config.params = params;
      delete config.data;
    } else {
      config.data = params;
      delete config.params;
    }

    const userStore = useUserState();
    if (userStore.token) {
      config.headers['Authorization'] = userStore.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response): Promise<any> => {
    // if (response.headers['content-type'] === 'application/octet-stream') {
    //   downloadBlob(response);
    //   return Promise.resolve()
    // }

    if (response.config.responseType === 'blob') {
      try {
        const text = await response.data.text()
        let jsonres = JSON.parse(text)
        if (jsonres && jsonres.code && jsonres.code !== 0) {
          throw { code: '1', message: jsonres.message || ERROR_MSG }
        } else {
          throw { code: '0' }
        }
      } catch (error:any) {
        if (error && error.code === '1') {
          Message.error(error.message);
          return Promise.reject(new Error(error.message));
        } else {
          downloadBlob(response);
          return Promise.resolve()
        }
      }
    }

    if ((response.config as Record<string, any>).noError) {
      return Promise.resolve(response.data);
    }

    const res = response.data;
    if (res.code === 10420 || res.code === 10421) {
      if (!dialogReactive) {
        const userStore = useUserState();
        const globalStore = useGlobalStore()
        const logout = () => {
          userStore.logout(true /* relogin */);
          globalStore.setAfterLoginPath(window.location.pathname);
        }
        dialogReactive = Dialog.warning({
          title: '登录过期',
          content: '账号登录过期，请重新登录',
          positiveText: '确定',
          onClose: logout,
          onPositiveClick: logout,
          onMaskClick: logout
        })
      }
      return Promise.reject(new Error(res));
    } else if (res.code !== 0 && res.code !== 4002) {
      Message.error(res.message || ERROR_MSG);
      return Promise.reject(new Error(res));
    }
  
    return Promise.resolve(res);
  },
  (error) => {
    console.log(error)
    if (error && error.response && error.response.status >= 400) {
      if (error.response.status === 500) {
        Message.error('数据异常，请刷新后重试，或联系管理员！');
      } else if (error.response.status === 429) {
        Message.error('操作太频繁，请稍后重试！');
      } else if (error.response.status === HttpStatus.RELOGIN) {
        // console.log("====", window.location.href.padEnd)
        if (!dialogReactive) {
          const userStore = useUserState();
          const logout = () => {
            userStore.logout(true /* relogin */);
          }
          dialogReactive = Dialog.warning({
            title: '登录过期',
            content: '账号登录过期，请重新登录',
            positiveText: '确定',
            onClose: logout,
            onPositiveClick: logout,
            onMaskClick: logout
          })
        }
        return Promise.reject(error)
      } else {
        Message.error(error.response.data.message || ERROR_MSG);
      }
    } else {
      Message.error('网络开小差了');
    }
    return Promise.reject(new Error(error));
  }
);

function downloadBlob(response: AxiosResponse) {
  const disposition = response.headers['content-disposition']
  const match = disposition.match(/filename\*=([^;]*)(;|$)/i);
  let filename = '';
  if (match) filename = window.decodeURIComponent(match[1]);

  if (filename) {
    filename = filename.replace(/UTF-8''/, '');
  }

  // 接受二进制文件流
  const blob = response.data;
  const blobUrl = window.URL.createObjectURL(blob);

  // 这里的文件名根据实际情况从响应头或者url里获取
  alinkDownload(
    {
      url: blobUrl,
      name: filename,
    },
  );
  window.URL.revokeObjectURL(blobUrl);
}



function requestCreater<T = any, K = any>(url: string, method: Method, options?: Record<string, any>) {
  return (data: T, customOptions?: Record<string, any>): Promise<BaseResult<K>> => {
    const mergeOptions = Object.assign({}, options, customOptions);
    return service<any, BaseResult<K>>({
      url,
      data,
      method,
      ...mergeOptions,
    })
  };
}

export default requestCreater;


export function requestSSE<T = any>(url: string, method: Method, options?: Record<string, any>) {
  return (data: T, customOptions?: Record<string, any>) => {
    let controller = new AbortController();
    const userStore = useUserState();
    const mergeOptions = Object.assign({
      method,
      headers: {
        'Authorization': userStore.token,
        'Content-Type': 'text/event-stream'
      },
      body: JSON.stringify(data),
      signal: controller.signal,
      openWhenHidden: true
    }, options, customOptions);

    if (!/^http/.test(url)) {
      url = baseUrl + url
    }
    fetchEventSource(url, mergeOptions)

    const closeSSE = () => {
      controller.abort();
    }
    return closeSSE
  };
}

interface EventSourceOptions {
  method: string
  onclose?: () => void
  onopen?: () => void
  onerror?: (e: any) => void
  onmessage?: (text: string) => void
  [k: string]: any
}

function _fetchEventSource(url: string, options: EventSourceOptions) {
  fetch(url, options)
    .then(res => {
      if (res.status === 200) {
        options.onopen && options.onopen();
        return res.body;
      }
    })
    .then(rb => {
      const reader = rb?.getReader();
      const push = (): Promise<undefined> | undefined => {
        return reader?.read().then(({ done, value }) => {
          if (done) {
            options.onclose && options.onclose();
            return;
          }
          options.onmessage && options.onmessage(new TextDecoder().decode(value));
          return push();
        })
      }
      return push();
    })
    .catch(e => {
      options.onerror && options.onerror(e)
    })
}
