<template>
  <n-popover ref="popoverRef" trigger="hover" style="margin-top: 26px;box-shadow: none;" placement="bottom"
    :show-arrow="false" raw>
    <div class="user-card" :style="themeStyle">
      <div class="vip-card-header" :class="{ 'center': !userInfo.expiry_time }">
        <svg-icon :class="{ 'icon': true, 'normal': userStore.isNormal }" name="vip"></svg-icon>
        <div class="info">
          <div class="vip-name">
            <template v-if="userStore.isNormal">
              <span>专属会员</span>
              <span class="btn" @click="onOpenVipDialog">立即开通 ></span>
            </template>
            <template v-else>
              <span>{{ userStore.userTypeName }}</span>
              <span v-if="userStore.isGroup">{{ userInfo.group_name }}</span>
              <span v-else-if="userStore.isRenewable" class="btn" @click="onOpenVipDialog">续费会员 ></span>
            </template>
          </div>
          <div class="vip-time" v-if="userInfo.expiry_time">会员有限期至：{{ userInfo.expiry_time }} </div>
        </div>
      </div>
      <div class="tips">
        {{ userStore.isNormal ? '解锁无限下载特权' : '会员高级权益尊享中' }}
      </div>
      <div class="user-menu">
        <div class="user-menu-item" @click="go('/user-center')">
          <svg-icon class="icon" name="user"></svg-icon>
          <span class="name">个人主页</span>
          <svg-icon class="icon" name="arrow-right"></svg-icon>
        </div>
        <div class="user-menu-item" @click="go('/my-order')">
          <svg-icon class="icon" name="order"></svg-icon>
          <span class="name">我的订单</span>
          <svg-icon class="icon" name="arrow-right"></svg-icon>
        </div>
        <div class="user-menu-item" @click="go('/my-coupon')">
          <svg-icon class="icon" name="order"></svg-icon>
          <span class="name">优惠券</span>
          <svg-icon class="icon" name="arrow-right"></svg-icon>
        </div>
        <!-- <div class="user-menu-item">
          <svg-icon class="icon" name="comment"></svg-icon>
          <span class="name">反馈问题</span>
        </div> -->
        <div class="user-menu-item kefu-box" v-if="!!companyConfig.customer_url">
          <svg-icon class="icon" name="customer-service"></svg-icon>
          <span class="name">专属客服</span>
          <img :src="companyConfig.customer_url" alt="" class="kefu-img">
        </div>
        <div class="user-menu-item" @click="onLogout">
          <svg-icon class="icon" name="exit"></svg-icon>
          <span class="name">退出登录</span>
        </div>
      </div>
    </div>
    <template #trigger>
      <slot></slot>
    </template>
  </n-popover>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useGlobalStore } from '@/store/useGlobalStore';
import { useUserState } from '@/store/useUserStore';
import { NPopover } from 'naive-ui';
import { ref, computed } from 'vue';
import { isMobileView } from '@/utils/index'

const isMobile = isMobileView();
const emit = defineEmits<{
  (e: 'customerService'): void
}>()

const userStore = useUserState();
const userInfo = computed(() => {
  return userStore.user_info
})

const router = useRouter();
const go = (path: string) => {
  router.push(path)
}

const onLogout = async () => {
  userStore.logout()
}

const themeStyle = computed(() => {
  let width: string = '';
  let height: string = '';
  if (isMobile) {
    width = 'calc(100vw - 2 * var(--m-content-padding))'
    height = 'calc(100vh - 93px)'
  }
  if (userStore.isGroup) {
    return {
      width,
      height,
      '--user-card-bg-color': '#EFF3FF',
      '--user-card-color': 'var(--primary)'
    }
  } else if (userStore.isVip) {
    return {
      width,
      height,
      '--user-card-bg-color': '#FFF5F0',
      '--user-card-color': '#F65907'
    }
  } else {
    return {
      width,
      height,
      '--user-card-bg-color': '#FFF5E9',
      '--user-card-color': '#EEA044'
    }
  }
})

const popoverRef = ref<typeof NPopover>()
const globalStore = useGlobalStore()
const onOpenVipDialog = () => {
  globalStore.setShowVipDialog(true)
  popoverRef.value?.setShow(false);
}
const companyConfig = computed(() => {
  return globalStore.companyConfig
})
</script>

<style lang="scss" scoped>
.user-card {
  width: 265px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  --user-card-bg-color: #EFF3FF;
  --user-card-color: var(--primary);

  .vip-card-header {
    height: 82px;
    display: flex;
    padding: 24px 26px;

    &.center {
      align-items: center;
    }

    .icon {
      font-size: 21px;
      color: var(--user-card-color);
      margin-top: 2px;
      margin-right: 5px;

      &.normal {
        color: #707070;
      }
    }

    .info {
      flex: 1;

      .vip-name {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 18px;

        .btn {
          cursor: pointer;
          color: var(--user-card-color);
        }
      }

      .vip-time {
        margin-top: 6px;
        color: #999999;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .tips {
    text-align: center;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    color: var(--user-card-color);
    background-color: var(--user-card-bg-color);
  }

  .user-menu {
    padding: 5px 0 10px;

    .user-menu-item {
      padding: 20px 30px;
      line-height: 22px;
      font-size: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: var(--user-card-color);
      }

      .name {
        flex: 1;
        overflow: hidden;
        margin: 0 6px;
      }
    }

    .kefu-box {
      position: relative;

      &:hover {
        .kefu-img {
          display: block;
        }
      }

      .kefu-img {
        display: none;
        position: absolute;
        right: 100%;
        top: 0;
        width: 265px;
        height: auto;
      }
    }
  }
}
</style>