import internal from 'stream';
import request from './axios'

// 获取分享链接
export const getShareUrl = request<any, { share_url: string}>(`/api/share/share_url`, 'POST');

// 获取活动信息
export const getActivity = request<{group_id: string}, any>(`/api/user/activity`, 'GET');

// 关闭活动banner
export const closeActivity = request(`/api/user/activity`, 'POST');

// 获取所有券的列表
export const getCouponList = request<{ used?:boolean, page: number, page_size: number }, {coupons: any[], total: number}>(`/api/user/coupon`, 'GET');