import { getActivity } from './activity'
import request from './axios'
import { useGlobalStore } from '@/store/useGlobalStore'

interface CommonRes<R, P> {
  roles: R
  positions: P
}

// 初始化公司配置
export const initCompanyConfig = () => request<any, any>('/api/user/company_info', 'GET')({
  // host: 'dev.futurecv.cn',
  host: location.host
}).then(res => {
  useGlobalStore().setCompanyConfig(res.data)
  getActivity({ group_id: useGlobalStore().gid }).then(res => {
    useGlobalStore().setActivityInfo(res.data)
  })
  return res
})

// 获取所有的角色和职位
export const getResumePosition = request<{ resume_type: string }, CommonRes<TFilterOption[], TFilterOption[]>>('/api/resume/position', 'GET')

// 获取所有地区
export const getRegion = request<any, CommonRes<TFilterOption[], null>>('/api/resume/region', 'GET')

// 获取地区高校
export const getRegionSchool = request<{ role: number }, CommonRes<null, TFilterOption[]>>('/api/resume/school_name', 'GET')