<template lang="pug">
.head(:style="`--head-opacity:${opacity}`")
  .top-add(v-if="showTopAd && activityInfo.home_show")
    img(class="home-top-add" src="@/assets/img/activity/home-top-ad.png" @click="goInvite")
    svg-icon(class="close-icon" name="close-white" @click="closeTopAd")
  .head_content
    .left_block
      router-link.logo(to="/")
        h1
          img(:src="companyConfig.black_logo_url")
        div {{ companyConfig.show_name }}
      .head_tabs.hide-m
        .head_tab(:class="{'active': activeRoute === '/my-resume'}" @click="goToView('/my-resume', true /* 需要登录 */)") 我的简历
        .head_tab(:class="{'active': activeRoute === '/template-center'}" @click="goToView('/template-center')") 简历模板
        .head_tab(:class="{'active': activeRoute === '/interview-helper'}" @click="goToView('/interview-helper')") 面试助手
        .head_tab(:class="{'active': activeRoute === '/interview'}" @click="goToView('/interview', true /* 需要登录 */)") 模拟面试
    .right_block
      div(class="mini-app" v-if="!companyConfig.third_party")
        svg-icon(name="mini-app" style="font-size: 16px;")
        span 小程序
        div(class="mini-app-card")
          img(class="mini-app-code" src="@/assets/img/miniapp.png")
          p(class="mini-app-text") 扫一扫，随时修改简历
      .hide-m(v-if="userStore.token")
        VipBtn(v-if="userStore.isNormal" type="gold" @click="onOpenVipDialog") 会员限时优惠
        VipCardPopover(v-else)
          VipBtn(:type="userStore.isGroup ? 'primary' : 'gold'") {{ userStore.userTypeName }}
      UserMenuPopover(v-if="userStore.token")
        div
          .avatar-box.hide-m
            n-avatar(v-if="userInfo.avatar" round size="large" :src="userInfo.avatar")
            .default-avatar(v-else) {{ avatarName }}
            svg-icon(v-if="userStore.isGroup" class="vip-sign-icon" name="diamond")
            svg-icon(v-else-if="userStore.isVip" class="vip-sign-icon gold" name="diamond")
          .menu-icon.hide-pc
            svg-icon(name="menu")
      n-button( v-else class='login_btn' type="primary" @click="onOpenLogin") 登录
  LoginDialog
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { NButton, NAvatar } from 'naive-ui'
import { useUserState } from '@/store/useUserStore';
import { useGlobalStore } from '@/store/useGlobalStore';
import VipBtn from './vipBtn.vue';
import VipCardPopover from './vipCardPopover.vue';
import UserMenuPopover from './userMenuPopover.vue';
import LoginDialog from './loginDialog/index.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { closeActivity, getActivity } from '@/api/activity'

const showTopAd = ref(true)

const opacity = ref(0)
const scrollHanlde = () => {
  opacity.value = document.documentElement.scrollTop / 300
}
onMounted(() => {
  window.addEventListener('scroll', scrollHanlde)
})

onUnmounted(() => {
  window.removeEventListener('scroll', scrollHanlde)
})

const route = useRoute();
const activeRoute = computed(() => {
  return route.path
});


const userStore = useUserState();
const userInfo = computed(() => {
  return userStore.user_info
})
const avatarName = computed(() => {
  let name = userInfo.value.name || ''
  let len = name.length;
  return name.substring(len - 4);
})

const globalStore = useGlobalStore()

const companyConfig = computed(() => {
  return globalStore.companyConfig
})
const activityInfo = computed(() => {
  return globalStore.activityInfo
})
const onOpenLogin = () => {
  globalStore.setShowLogin(true)
}
const onOpenVipDialog = () => {
  globalStore.setShowVipDialog(true)
}

const router = useRouter();
const goToView = (path: string, needLogin?: boolean) => {
  if (needLogin && !userStore.token) {
    globalStore.setAfterLoginPath(path);
    onOpenLogin()
    return;
  }

  router.push({ path: path });
}

const goInvite = () => {
  if (userStore.token) {
    router.push('/invite');
  } else {
    onOpenLogin()
  }
}
const closeTopAd = () => {
  showTopAd.value = false
  closeActivity({}).then(res => {
    getActivity({ group_id: useGlobalStore().gid }).then(res => {
      useGlobalStore().setActivityInfo(res.data)
    })
  })
}
</script>

<style scoped lang="sass">
.head
  position: fixed
  z-index: 99
  top: 0
  left: 0
  width: 100%
  min-width: var(--content-min-width)
  background-color: rgba($color: #F6F8FF, $alpha: var(--head-opacity))
  .top-add
    position: relative
    width: 100%
    background-image: url(@/assets/img/activity/ad-bg.jpg)
    background-size: 100% 100%
    background-repeat: no-repeat
    cursor: pointer
    .home-top-add
      width: 100%
      height: 45px
      object-fit: none
    .close-icon
      width: 20px
      height: 20px
      position: absolute
      top: 13px
      right: 10px
  .head_content
    width: 100%
    max-width: var(--content-width)
    min-width: var(--content-min-width)
    padding: 0 var(--content-padding) 0 var(--content-padding)
    height: 80px
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 auto
    .left_block
      display: flex
      align-items: center
      .logo
        color: #333333
        font-size: 20px
        display: flex
        align-items: center
        cursor: pointer
        margin-right: 80px 
        img
          width: auto
          height: 34px
          margin-right: 8px
      .head_tabs
        display: flex
        color: #333333
        font-weight: bold
        font-size: 16px
        .head_tab
          cursor: pointer
          margin-right: 45px
          &:hover,
          &.active
            color: var(--primary)
    .right_block
      display: flex
      align-items: center
      .mini-app
        cursor: pointer
        position: relative
        padding: 10px
        margin-right: 20px
        span 
          padding-left: 5px
          font-size: 16
        &:hover
          .mini-app-card
            transition: padding 0.2s
            height: auto
            padding: 25px
            box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16)
        .mini-app-card
          transition: padding 0.2s
          box-sizing: border-box
          overflow: hidden
          height: 0
          position: absolute
          top: 40px
          left: 50%
          transform: translateX(-50%)
          background: #F6F9FF
          border-radius: 10px
          background-color: #F6F9FF
          background-image: url(@/assets/img/mini-code-bg.svg)
          bacpackground-size: 100% auto
          background-repeat: no-repeat
          background-position: 0 0
          .mini-app-code
            max-width: unset
            width: 150px
            height: 150px
          .mini-app-text
            margin-top: 18px
            color: #1A1A1A
            text-align: center
      .vip-btn
        margin-right: 30px
      .login_btn
        width: 120px
      .avatar-box
        position: relative
        cursor: pointer

        .default-avatar
          display: flex
          align-items: center
          justify-content: center
          background-color: var(--primary)
          color: #fff
          font-size: 12px
          width: 40px
          height: 40px
          border-radius: 50%
        .vip-sign-icon 
          position: absolute
          bottom: 0
          right: 0
          color: var(--primary)
          font-size: 15px

          &.gold
            color: #C36D21

// 移动端样式兼容
#app.m-view
  .head
    height: 55px
    background: #fff
    .logo
      font-size: 16px
      img
        width: 23px
        height: 23px
        margin-right: 4px
    .menu-icon
      font-size: 16px
    .login_btn
      width: 60px
      height: 22px
      font-size: 12px
</style>